.cities {
  font-size: 14px;
}
.definition {
  font-size: 16px;
  font-family: courier, monospace;
}
.title {
  font-family: "Mrs Sheppards", cursive;
  font-size: 48px;
}
.mainWindow {
  background-color: transparent;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./media/brown-carrots-sm.jpg");
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
}
.menu__item__arrow__collapsed {
  transition: opacity 0.3s, transform 0.3s;
}
.menu__item__arrow__expanded {
  transition: opacity 0.3s, transform 0.3s;
  transform: rotate(90deg);
}
@media (min-width: 576px) {
  .cities {
    font-size: 20px;
  }
  .definition {
    font-size: 22px;
  }
  .title {
    font-size: 72px;
  }
  .mainWindow {
    background-image: none;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
